import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import ScrollingText from '../components/ScrollingText'
import styled from '@emotion/styled'
import ImageGallery from '../components/ImageGallery'
import Quote from '../components/Quote'
import Results from '../components/Results'
import Download from '../components/Download'
import TextEffect from '../components/riseEffect/TextEffect'
import Media from '../components/Media'
// import Viewer from '../viewer/index'

import loadable from '@loadable/component'
const Viewer = loadable(() => import('../viewer/index'))

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    metaDescription,
    slug,
    heroImage,
    heroVideo,
    body,
    publishDate,
    bodyTitle,
    abstract,
    paragraph,
    quote,
    quotePerson,
    quoteTitle,
    metric1Result,
    metric1Title,
    metric2Result,
    metric2Title,
    metric3Result,
    metric3Title,
    images,
    download,
    model,
  } = data.contentfulPost

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  const HeroImageContainer = styled.div`
    padding-top: 40px;
    border-top: 1px solid white;

    .gatsby-image-wrapper {
      div:first-of-type {
        padding-bottom: 63% !important;
      }
    }

    @media (max-width: 700px) {
      .gatsby-image-wrapper {
        div:first-of-type {
          padding-bottom: 100% !important;
        }
      }
    }
  `

  const TitleContainer = styled.section`
    margin: 40px 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  `

  const TextContainer = styled.section`
    padding: 40px 0 80px 0;
    display: flex;
    justify-content: space-between;

    .leftText {
      display: flex;
    }

    .abstract {
      width: 350px;
      font-size: 1.6rem;

      p {
        line-height: 1.3;
      }
    }

    .paragraph {
      width: 375px;
      margin-left: 100px;
      font-weight: 300;

      p {
        line-height: 1.6;
      }
    }

    .date {
      p {
        font-size: 0.8rem;
        font-weight: 700;
      }

      span {
        display: block;
      }
    }

    @media (max-width: 1050px) {
      .leftText {
        width: 100%;
        flex-direction: column;
      }

      .abstract {
        width: 570px;
        margin-bottom: 30px;
      }

      .paragraph {
        width: 570px;
        margin-left: 0;
      }
    }

    @media (max-width: 840px) {
      .abstract {
        width: 85%;
      }

      .paragraph {
        width: 85%;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column-reverse;

      .date {
        margin-bottom: 15px;

        span {
          display: inline-block;
        }
      }
    }

    @media (max-width: 550px) {
      .abstract {
        width: 100%;
      }

      .paragraph {
        width: 100%;
      }
    }
  `

  const ModelContainer = styled.div`
    height: 500px;
  `

  return (
    <div>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        slug={slug}
        image={ogImage}
      />
      <Container>
        <HeroImageContainer>
          {heroVideo ? (
            <Media
              videoCheck={heroVideo.file.url.slice(-3)}
              videoSrcURL={heroVideo.file.url}
              fluid={heroVideo.fluid}
              alt={heroVideo.title}
              title={heroVideo.title}
              description={heroVideo.description}
            />
          ) : (
            <Img fluid={heroImage.fluid} />
          )}
        </HeroImageContainer>

        <TitleContainer>
          <ScrollingText text={title} />
        </TitleContainer>

        <TextContainer>
          <div className="leftText">
            <div className="abstract">
              <TextEffect lineHeight={1.3} text={abstract.abstract} />
            </div>

            <div className="paragraph">
              <TextEffect lineHeight={1.6} text={paragraph.paragraph} />
            </div>
          </div>

          <div className="date">
            <p>
              {publishDate.substr(0, 3).toUpperCase()}&nbsp;
              <span>{publishDate.substr(publishDate.length - 4)}</span>
            </p>
          </div>
        </TextContainer>

        <ImageGallery images={images} />

        <Quote
          quote={quote}
          quotePerson={quotePerson}
          quoteTitle={quoteTitle}
        />

        <Results
          metric1Result={metric1Result}
          metric1Title={metric1Title}
          metric2Result={metric2Result}
          metric2Title={metric2Title}
          metric3Result={metric3Result}
          metric3Title={metric3Title}
        />
        <PageBody body={body} bodyTitle={bodyTitle} />
        <Download download={download} />

        {/* {model ? (
          <ModelContainer>
            <Viewer src={`https:${model.file.url}`} />
          </ModelContainer>
        ) : null} */}
      </Container>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      heroVideo {
        file {
          url
        }
        description
        title
      }
      bodyTitle
      body {
        body
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      paragraph {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      metric1Result
      metric1Title
      metric2Result
      metric2Title
      metric3Result
      metric3Title
      abstract {
        abstract
        childMarkdownRemark {
          html
        }
      }
      paragraph {
        paragraph
        childMarkdownRemark {
          html
        }
      }
      images {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      quote {
        quote
        childMarkdownRemark {
          html
        }
      }
      quotePerson
      quoteTitle
      download {
        file {
          url
          fileName
        }
      }
      model {
        file {
          url
        }
      }
    }
  }
`

export default PostTemplate
